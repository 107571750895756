<template>
  <div>
    <div class="flex-align-center">
      <el-button type="primary" :disabled="disabled" @click="addNav"
        >添加导航</el-button
      >
      <p v-if="tips" class="tips">
        {{ tips }}
      </p>
    </div>
    <el-table
      :id="`sortTable${id}`"
      row-key="sort_id"
      :data="modelList"
      class="thead-light"
      stripe
      :style="{ marginTop: '10px' }"
    >
      <el-table-column
        v-for="(item, index) in sortColumn"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.min_width"
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <!--排序 -->
          <div v-if="item.prop === 'sort'">
            <i class="el-icon-sort"></i>
          </div>
          <!-- 图标 -->
          <list-image
            v-else-if="item.prop === 'icon'"
            :src="scope.row[item.prop]"
            borderRadius="50%"
            fit="cover"
          ></list-image>
          <!-- 页面类型 -->
          <div v-else-if="item.prop === 'type'">
            <span>
              {{ scope.row.type ? '文章分类' : '设置导航' }}
            </span>
          </div>
          <!-- 是否启用 -->
          <div v-else-if="item.prop === 'is_show'">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="scope.row.is_show"
            ></el-switch>
          </div>
          <div v-else-if="item.prop === 'bg_color'">
            <span>
              {{
                scope.row.type
                  ? bgList.find((t) => t.label === scope.row.bg_color).value
                  : '--'
              }}
            </span>
          </div>
          <span v-else>{{ scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleEditNav(scope.row)"
            >编辑
          </el-button>
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleDelNav(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <AddNavDialog
      :article_subject_id="article_subject_id"
      v-model="showAddNavDialog"
      :editForm="editForm"
      @change="addNavSuccess"
    />
  </div>
</template>

<script>
import AddNavDialog from './AddNavDialog'
import ListImage from '@/base/components/List/ListImage'
import Sortable from 'sortablejs/modular/sortable.core.esm.js'

export default {
  components: { AddNavDialog, ListImage },
  model: {
    prop: 'sortList',
    event: 'changeSortList',
  },
  props: {
    article_subject_id: {
      type: [String, Number],
    },
    sortList: {
      type: Array,
      default: () => [],
    },
    tips: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 999,
    },
    id: {
      type: [String, Number],
      default: 1,
    },
    defaultField: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortColumnList: [
        { label: '拖拽排序', prop: 'sort', min_width: 100 },
        { label: '图标', prop: 'icon', min_width: 100 },
        { label: '导航名称', prop: 'name', min_width: 100 },
        { label: '页面类型', prop: 'type', min_width: 100 },
        { label: '导航至', prop: 'to_name', min_width: 100 },
        { label: '启用', prop: 'is_show', min_width: 100 },
        { label: '页面主题色', prop: 'bg_color', min_width: 100 },
      ],
      showAddNavDialog: false,
      bgList: [
        { label: 'yellow', value: '黄色' },
        { label: 'blue', value: '蓝色' },
        { label: 'red', value: '红色' },
        { label: 'green', value: '绿色' },
      ],
      editForm: null,
    }
  },
  computed: {
    modelList: {
      get() {
        return this.$props.sortList
      },
      set(val) {
        this.$emit('changeSortList', val)
      },
    },
    sortColumn() {
      return this.sortColumnList.filter(
        (t) =>
          this.$props.defaultField.findIndex((f) => f.label == t.prop) === -1
      )
    },
  },
  methods: {
    //初始化拖拽表格
    initSortable() {
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          `#sortTable${this.$props.id}  .el-table__body>tbody`
        )
        console.log(sortTable)
        this.$sortTable = Sortable.create(sortTable, {
          animation: 150,
          ghostClass: 'sortable-ghost',
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex)
            if (event.oldIndex === event.newIndex) {
              return
            }
            // 拿到拖拽的item
            const oldItem = this.modelList[event.oldIndex]
            // 删除原本位置
            this.modelList.splice(event.oldIndex, 1)
            // 添加到新的位置
            this.modelList.splice(event.newIndex, 0, oldItem)
            let idArray = this.modelList.map((item) => item.id)
          },
        })
        //阻止火狐拖拽新建新页面
        document.body.addEventListener('drop', this.preventDrop, false)
      })
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable) this.$sortTable.destroy()
      document.body.removeEventListener('drop', this.preventDrop, false)
    },
    //阻止默认drop事件
    preventDrop(event) {
      event.preventDefault()
      event.stopPropagation()
    },

    handleEditNav(row) {
      this.editForm = row
      this.showAddNavDialog = true
    },

    // 删除此会员
    handleDelNav(row) {
      this.$confirm('是否删除此导航?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let index = this.modelList.findIndex((item) => item.id === row.id)
          this.modelList.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {})
    },
    addNav() {
      if(this.modelList.length >= 8){
        this.$message({
          type: 'warning',
          message: '最多添加8个导航!',
        })
        return
      }
      this.editForm = null
      this.showAddNavDialog = true
    },
    // 添加会员成功
    addNavSuccess(form) {
      
      if (this.editForm) {
        this.modelList = this.modelList.map((item, index) => {
          if (item.sort_id === this.editForm.sort_id) {
            return form
          }
          return item
        })
        this.editForm = null
      } else {
        this.modelList.push(form)
      }
    },
  },
  mounted() {
    this.initSortable()
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable()
  },
}
</script>

<style lang="scss" scoped>
.tips {
  margin-left: 20px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
