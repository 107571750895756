import { render, staticRenderFns } from "./AddNavDialog.vue?vue&type=template&id=3b298194&scoped=true&"
import script from "./AddNavDialog.vue?vue&type=script&lang=js&"
export * from "./AddNavDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddNavDialog.vue?vue&type=style&index=0&id=3b298194&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b298194",
  null
  
)

export default component.exports