<!--
 * @Author: dongjia
 * @Date: 2021-08-09 10:30:03
 * @LastEditTime: 2022-01-18 14:52:22
 * @LastEditors: aleaner
 * @Description: 业务组件数据选择弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\app-design\components\Normal\dataSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="`选择${dialogTitle}数据`"
    :visible.sync="openDialog"
    width="850px"
    :show-close="true"
    class="dialog-vertical consult data-selector"
    append-to-body
    close-on-click-modal
    close-on-press-escape
  >
    <div class="dialog-content">
      <div class="data-list-header">
        <div class="header-top">
          <div class="header-btn-group">
            <!-- 弹窗没有节点 -->
            <el-button
              v-if="handleAddRouterName"
              class="item-btn"
              type="primary"
              size="small"
              @click="handleAdd"
              >新建</el-button
            >
            <el-button class="item-btn" size="small" @click="handleAdmin"
              >管理</el-button
            >
            <el-button class="item-btn" size="small" @click="refreshList"
              >刷新</el-button
            >
          </div>
          <div class="header-filter">
            <template v-if="filterForm.component.indexOf('channels') === -1">
              <el-input
                style="width: 160px"
                v-model="filterForm.keyword"
                :placeholder="`搜索${dialogTitle}名称`"
                @change="handleFilter"
              >
                <i class="el-icon-search el-input__icon" slot="suffix"></i>
              </el-input>
            </template>
            <template v-else>
              <st-channels-category-filter
                v-model="filterForm.category_id"
                :multiple="false"
                style="width: 160px"
                @change="handleFilter"
              />
              <el-input
                style="width: 160px"
                v-model="filterForm.account_id"
                placeholder="搜索视频号ID"
                size="medium"
                @change="handleFilter"
              >
                <i class="el-icon-search el-input__icon" slot="suffix"></i>
              </el-input>
              <template
                v-if="filterForm.component === 'st-channels-video-list'"
              >
                <el-input
                  style="width: 160px"
                  v-model="filterForm.feed_id"
                  placeholder="搜索视频ID"
                  size="medium"
                  @change="handleFilter"
                >
                  <i class="el-icon-search el-input__icon" slot="suffix"></i>
                </el-input>
              </template>
              <template
                v-else-if="filterForm.component === 'st-channels-live-list'"
              >
                <el-input
                  style="width: 160px"
                  v-model="filterForm.theme"
                  placeholder="搜索直播主题"
                  size="medium"
                  @change="handleFilter"
                >
                  <i class="el-icon-search el-input__icon" slot="suffix"></i>
                </el-input>
              </template>
            </template>
            <template
              v-if="
                [
                  'st-activity-list',
                  'st-article-list',
                  'st-shop-goods',
                  'st-online-learning',
                  'st-spirit-list',
                  'st-enterprise-list-gov',
                ].includes(filterForm.component)
              "
            >
              <el-select
                v-model="filterForm.data_sources"
                multiple
                collapse-tags
                style="margin-left: 20px"
                placeholder="筛选数据类型"
                @change="handleFilter"
              >
                <el-option
                  v-for="item in dataSourcesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </div>
        </div>
        <div class="header-bottom">
          <div class="header-filter"></div>
        </div>
        <el-table
          :data="dataList"
          style="width: 100%; cursor: pointer; margin-top: 10px"
          :height="250"
          class="table"
          ref="multipleTable"
          @row-click="handleCurrentChange"
          v-loading="loading"
          v-loadmore="LoadMore"
        >
          <!-- 多选 -->
          <el-table-column fixed width="70">
            <template slot-scope="scope">
              <el-radio v-model="selectId" :label="scope.row.id"
                ><span></span>
              </el-radio>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column
              show-overflow-tooltip
              :prop="th.prop"
              :label="th.label"
              :min-width="th.minWidth || ''"
              :key="index"
            >
              <template slot-scope="scope">
                <div class="status-text" v-if="th.type === 'status_text'">
                  <div
                    class="status-dot"
                    :style="{
                      backgroundColor: scope.row[th.status_text_field],
                    }"
                  ></div>
                  <div>{{ scope.row[th.prop] | placeholder }}</div>
                </div>
                <list-image
                  v-else-if="
                    scope.row[th.prop] &&
                    (th.prop === 'cover' ||
                      th.prop === 'image' ||
                      th.prop === 'icon' ||
                      th.prop === 'avatar')
                  "
                  :src="scope.row[th.prop]"
                ></list-image>
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dataList } from '../../api/consult-content-list/category.js'
import StChannelsCategoryFilter from '@/modules/app-design/components/Form/StChannelsCategoryFilter'
import ListImage from '@/base/components/List/ListImage'
import debounce from 'lodash/debounce'
export default {
  name: 'dataSelector',
  components: { ListImage, StChannelsCategoryFilter },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      default: 'st-special-article',
    },
    selected: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false, //加载
      dialogTitle: '专题',
      handleAddRouterName: 'addBrand',
      handleAdminRouterName: 'CycBrandList',
      thead: [],
      dataList: [],
      //筛选对象
      filterForm: {
        category_id: -1,
        keyword: '', //关键词
        page_size: 10, //每页多少条数据
        page: 1,
        component: '',
        account_id: '', //视频号ID
        theme: '', //视频号直播主题
        feed_id: '', //视频号视频ID
        // 活动列表数据类型默认
        data_sources: ['self_data', 'gov_data'],
      },
      getable: true, //是否可以请求列表
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
      },
      dataSourcesOptions: [
        {
          label: '自有平台数据',
          value: 'self_data',
        },
        {
          label: '协会互联数据',
          value: 'gov_data',
        },
      ],
      categoryList: [],
      selectId: '', //勾选参数
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.refreshList()
      },
    },
    openDialog(val) {
      if (val) {
        // this.dataList.forEach((el) => {
        //   if (this.selected.findIndex((elem) => elem.id === el.id) !== -1) {
        //     this.$nextTick(() => {
        //       this.$refs.multipleTable.toggleRowSelection(el, true);
        //     });
        //   }
        // });
        if (
          this.selected &&
          this.dataList.some((t) => t.id === this.selected)
        ) {
          this.selectId = this.selected
        }
        this.getDataList(this.filterForm)
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  created() {
    // 获取传递的标识
    this.filterForm.component = this.dataType
  },
  methods: {
    // 获取数据列表
    getDataList(pageData) {
      // 是否可以请求列表
      if (this.getable) {
        this.loading = true
        console.log(pageData)

        dataList({ ...pageData, ...this.form })
          .then((res) => {
            try {
              const { data } = res
              this.handleAddRouterName = data.button_new
              this.handleAdminRouterName = data.button_manage
              this.dialogTitle = data.name
              this.thead = data.table
              if (pageData.page === 1) {
                this.dataList = data.list.data
              } else {
                this.dataList = [...this.dataList, ...data.list.data]
              }
              if (data.list.data.length < this.filterForm.page_size) {
                this.getable = false
              }
              this.filterForm.page++
              if (
                !this.selectId &&
                this.selected &&
                this.dataList.some((t) => t.id === this.selected)
              ) {
                this.selectId = this.selected
              }
            } catch (e) {
              console.log(e)
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const filterData = { ...this.filterForm }
      filterData.page = key ? filterData.page : 1
      if (!key) {
        this.filterForm.page = 1
      }
      console.log(filterData.page, 'filterData.page', key)
      this.getable = true
      this.getDataList(filterData)
    },

    // 点击数据项操作
    handleCurrentChange(val) {
      this.selectId = val.id
    },
    //触底刷新
    LoadMore: debounce(function () {
      console.log('loadmore')
      if (this.getable && this.dataList.length >= 10) {
        this.handleFilter(this.filterForm, 'Load')
      }
    }, 200),
    // 刷新
    refreshList() {
      this.getable = true
      this.handleFilter({ ...this.filterForm, page: 1 })
    },
    // 点击新增
    handleAdd() {
      let routerData = this.$router.resolve({
        name: this.handleAddRouterName,
        params: { id: 0, type: 'add' },
      })
      window.open(routerData.href, '_blank')
    },
    // 点击管理
    handleAdmin() {
      let routerData = this.$router.resolve({
        name: this.handleAdminRouterName,
      })
      window.open(routerData.href, '_blank')
    },
    // 确定
    submit() {
      if (!this.selectId) {
        this.$message.info('请选择数据项')
      } else {
        this.$emit(
          'change',
          this.dataList.find((t) => t.id === this.selectId)
        )
        this.openDialog = false
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.data-list-header {
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-btn-group {
    }
    .header-filter {
      .el-input + .el-input {
        margin-left: 10px;
      }

      .el-select + .el-input {
        margin-left: 10px;
      }
    }
    .header-filter + .header-filter {
      margin-left: 10px;
    }
  }
}
.data-list {
  margin-top: 20px;
}
.status-text {
  display: flex;
  align-items: center;
  .status-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
