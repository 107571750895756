<template>
  <el-dialog
    title="设置导航"
    :visible="openDialog"
    width="400px"
    class="dialog-vertical"
    @close="closeAdd"
  >
    <el-form
      :model="form"
      size="medium"
      label-width="100px"
      ref="form"
      @submit.native.prevent
    >
      <el-form-item label="导航名称：">
        <el-input maxlength="4" show-word-limit v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="页面类型：" prop="type">
        <el-radio-group @change="change" v-model="form.type">
          <el-radio :label="0">链接</el-radio>
          <el-radio :label="1">文章分类</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="导航图标：">
        <single-media-wall
          v-model="form.icon"
          :width="80"
          :height="80"
          ratio="160:160"
        >
        </single-media-wall>
      </el-form-item>
      <el-form-item v-if="form.type === 1" label="选择分类：">
        <el-cascader
          v-model="form.id"
          :options="categories"
          :props="casProps"
          @change="handleLevelChange"
          ref="levels"
          :show-all-levels="false"
          placeholder="请选择分类"
          clearable
        >
        </el-cascader>
      </el-form-item>
      <el-form-item v-else label="跳转链接：">
        <el-button
          class="jump_link"
          type="default"
          plain
          @click="showLinkConfigDialog = true"
        >
          {{
            form.jump_link ? form.jump_link.jump_config.name : '设置链接地址'
          }}
        </el-button>
        <el-button
          v-if="form.jump_link"
          type="text"
          @click="form.jump_link = null"
          >删除
        </el-button>
      </el-form-item>
      <el-form-item v-if="form.type === 1" label="页面主题色：">
        <el-radio-group v-model="form.bg_color">
          <el-radio
            v-for="(bg, index) in bgList"
            :key="index"
            :label="bg.label"
          >
            {{ bg.value }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button size="small" type="primary" @click="handleAddCategory"
        >确认</el-button
      >
    </template>
    <link-config-dialog v-model="showLinkConfigDialog" @change="linkChange" />
  </el-dialog>
</template>

<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import LinkConfigDialog from '@/modules/app-design/components/Normal/LinkConfigDialog.vue'
import { articleCategories } from '@/modules/consult/api/consult-page-config'
import { randomString } from '@/base/utils/tool'
export default {
  name: 'AddCategory',
  components: {
    SingleMediaWall,
    LinkConfigDialog,
  },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    article_subject_id: {
      type: String,
    },
    editForm: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        icon: '',
        type: 0,
        id: '',
        name: '',
        to_name: '',
        is_show: 1,
        jump_link: null,
        bg_color: 'yellow',
        sort_id: '',
      },
      categories: [],
      // 级联属性
      casProps: {
        children: '_children',
        label: 'name',
        value: 'id',
        expandTrigger: 'hover',
        emitPath: false,
        checkStrictly: true,
      },
      showLinkConfigDialog: false,
      bgList: [
        { label: 'yellow', value: '黄色' },
        { label: 'blue', value: '蓝色' },
        { label: 'red', value: '红色' },
        { label: 'green', value: '绿色' },
      ],
    }
  },
  watch: {
    '$props.article_subject_id'(val) {
      if (!val) return
      this.getAllCategories(val)
    },
    openDialog(val) {
      if (val && this.editForm) {
        this.form = { ...this.editForm }
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  methods: {
    change(e) {
      this.form.to_name = ''
      if (e === 1) {
        if (this.categories.length === 0) {
          this.$message.error('当前文章专暂无文章分类，请添加文章分类')
          this.form.type = 0
        }
      }
      this.form.id = ''
      this.form.jump_link = null
    },
    linkChange(data) {
      this.form.jump_link = data
      this.form.to_name = data.jump_config.name
    },
    handleAddCategory() {
      if (!this.form.id && this.form.type === 1) {
        return this.$message.error('请选择文章分类')
      }
      if (!this.form.jump_link && this.form.type === 0) {
        return this.$message.error('请选择跳转链接')
      }
      if (!this.form.icon) {
        return this.$message.error('请选择图标')
      }
      if (!this.form.name) {
        return this.$message.error('请填写导航名称')
      }
      if (!this.form.sort_id) {
        this.form.sort_id = randomString(8)
      }

      this.$emit('change', this.form)
      this.closeAdd()
    },
    closeAdd() {
      this.openDialog = false
      this.form = {
        icon: '',
        type: 0,
        id: '',
        name: '',
        to_name: '',
        is_show: 1,
        jump_link: null,
        bg_color: 'yellow',
        sort_id: '',
      }
    },
    getAllCategories(id) {
      articleCategories({ id })
        .then((res) => {
          this.categories = [...res.data.list]
        })
        .catch((err) => {})
    },
    handleLevelChange(e) {
      this.form.to_name =
        this.categories.find((item) => item.id === e).name || ''
      this.$refs.levels.dropDownVisible = false
    },
  },
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 20px;
}
.el-radio {
  margin-bottom: 10px;
}
</style>
