import api from '@/base/utils/request'

export const getPageConfig = (data) => {
  return api({ url: '/admin/consult/Settings/getPageConfig', method: 'post', data })
}

export const savePageConfig = (data) => {
  return api({url:'/admin/consult/Settings/savePageConfig', method: 'post', data})
}

export const articleCategories = (data)=>{
  return api({url:'/admin/consult/Settings/articleCategories', method: 'post', data})
}