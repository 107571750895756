import { render, staticRenderFns } from "./consult-page-config.vue?vue&type=template&id=fccd7046&scoped=true&"
import script from "./consult-page-config.vue?vue&type=script&lang=js&"
export * from "./consult-page-config.vue?vue&type=script&lang=js&"
import style0 from "./consult-page-config.vue?vue&type=style&index=0&id=fccd7046&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fccd7046",
  null
  
)

export default component.exports