<template>
  <div v-loading="pagesLoading" class="content flex">
    <!-- <div style="margin-right: 20px">
      <div style="width: 315px; height: 600px; overflow: auto"></div>
    </div> -->
    <div>
      <el-form
        class="save-content"
        label-width="100px"
        :model="form"
        ref="saveForm"
        :rules="rules"
      >
        <h2 class="config-section-title">页面配置</h2>
        <div style="width: 400px">
          <el-form-item prop="page.title" label="页面标题：">
            <el-input
              maxlength="4"
              show-word-limit
              v-model="form.page.title"
            ></el-input>
          </el-form-item>
          <el-form-item prop="page.ask" label="问答板块：">
            <el-input
              maxlength="4"
              show-word-limit
              v-model="form.page.ask"
            ></el-input>
          </el-form-item>
          <el-form-item prop="page.topic" label="话题板块：">
            <el-input
              maxlength="4"
              show-word-limit
              v-model="form.page.topic"
            ></el-input>
          </el-form-item>
        </div>
        <h2 class="config-section-title">内容配置</h2>
        <el-form-item prop="article_subject.id" label="专题配置：">
          <div
            v-if="form.article_subject && form.article_subject.id"
            class="flex-align-center"
          >
            <div class="article_subject">
              {{ form.article_subject.name }}
            </div>
            <el-button type="primary" @click="showTypeSelector = true"
              >修改</el-button
            >
          </div>
          <el-button v-else type="primary" @click="showTypeSelector = true"
            >选择专题</el-button
          >
        </el-form-item>
        <el-form-item label="导航入口：">
          <NavTable
            v-model="form.nav_list"
            :article_subject_id="
              form.article_subject && form.article_subject.id
                ? form.article_subject.id
                : ''
            "
            :disabled="!(form.article_subject && form.article_subject.id)"
          />
        </el-form-item>

        <div class="modular">
          <h2 class="modular-title">分享配置</h2>
          <div class="modular-content">
            <el-form-item label="分享标题：" prop="share.title">
              <el-input
                :style="{
                  maxWidth: '500px'
                }"
                v-model="form.share.title"
              ></el-input>
            </el-form-item>
            <el-form-item label="分享图片：" prop="share.image">
              <ImageItem
                v-model="form.share.image"
                :width="150"
                :height="120"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <FixedActionBar>
        <el-button type="primary" @click="saveConfig('saveForm')"
          >保存
        </el-button>
      </FixedActionBar>
    </div>
    <dataSelector
      :selected="
        form.article_subject && form.article_subject.id
          ? form.article_subject.id
          : ''
      "
      v-model="showTypeSelector"
      @change="selected"
    />
  </div>
</template>
<script>
import ImageItem from '@/modules/common/components/ImageItem.vue'

import FixedActionBar from '@/base/layout/FixedActionBar'
import NavTable from '@/modules/consult/components/ConsultPageConfig/NavTable.vue'
import dataSelector from '@/modules/consult/components/ConsultPageConfig/dataSelector.vue'
import {
  getPageConfig,
  savePageConfig,
} from '@/modules/consult/api/consult-page-config'
export default {
  components: {
    FixedActionBar,
    dataSelector,
    NavTable,
    ImageItem,
  },
  data() {
    return {
      form: {
        page: {},
        article_subject: {},
        share: {
          image: '',
        },
      },
      rules: {
        'page.title': [
          { required: true, message: '请填写页面标题', trigger: 'blur' },
        ],
        'page.ask': [
          { required: true, message: '请填写问答板块', trigger: 'blur' },
        ],
        'page.topic': [
          { required: true, message: '请填写话题板块', trigger: 'blur' },
        ],
        'article_subject.id': [
          { required: true, message: '请选择文章专栏', trigger: 'blur' },
        ],
      },
      pagesLoading: true,
      showTypeSelector: false,
    }
  },
  methods: {
    saveConfig(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          savePageConfig({ config: this.form }).then((res) => {
            this.$message.success('保存成功')
            this.getConfig()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getConfig() {
      this.pagesLoading = true
      getPageConfig().then((res) => {
        console.log(res)
        this.pagesLoading = false
        this.form = res.data
      })
    },
    selected(data) {
      if (this.form.article_subject) {
        this.$confirm(
          '修改专题配置，需要重新配置导航入口文章分类',
          '确认信息',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '放弃修改',
          }
        )
          .then(() => {
            this.form.article_subject = data
            this.form.nav_list = this.form.nav_list.filter(
              (item) => item.type !== 1
            )
          })
          .catch((action) => {})
      } else {
        this.form.article_subject = data
      }
    },
    navSelected(data) {
      this.form.nav_list.push(data)
    },
  },
  mounted() {
    this.getConfig()
  },
}
</script>
<style lang="scss" scoped>
.form-tips {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #b3b3b3;
  line-height: 1.5;
  margin-top: 8px;
  span + span {
    margin-left: 4px;
  }
}
.article_subject {
  max-width: 100px;
  margin-right: 10px;
  // height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
